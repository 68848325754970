<template>
  <div class="filterSearch">
    <div class="clearfix">
      <!-- <CompanySelect
        ref="companySelect"
        class="fl"
        v-model="search.company_name"
        style="width: 20%"
        :countryId="search.country_id"
        :cityId="search.city"
        :placeholder="$t('companyName')"
      ></CompanySelect> -->
      <el-input
        size="small"
        v-model="search.company_name"
        class="inputWidth fl"
        :placeholder="$t('companyName')"
      ></el-input>

      <el-input
        size="small"
        v-model="search.username"
        class="inputWidth fl"
        :placeholder="$t('name')"
      ></el-input>
      <CountrySelect
        class="fl"
        style="width: 15%"
        v-model="search.country_id"
        :locale="LOCALE"
        :placeTitle="$t('country')"
      ></CountrySelect>
      <CitySelect
        class="fl"
        style="width: 15%; margin: 0 16px"
        v-model="search.city_id"
        size="small"
        :locale="LOCALE"
        :countryId="search.country_id"
        :placeTitle="$t('city')"
      ></CitySelect>
      <div class="box fl" @click="show_more = !show_more">
        <i
          :class="[show_more ? 'el-icon-minus' : 'el-icon-plus']"
          class="i_rotate"
        ></i>
      </div>
      <span class="fr">
        <el-button size="small" type="primary" @click="searchChange">{{
          $t("search")
        }}</el-button>
        <el-button size="small" @click="resetChange">{{
          $t("reset")
        }}</el-button>
      </span>
    </div>
    <div v-show="show_more">
      <div class="clearfix haveSelect">
        <p class="fl companyScreen">
          {{ $t("companyScreen") }}
        </p>
        <el-popover
          @show="showBusiness"
          placement="right-start"
          width="600"
          trigger="click"
          v-model="bussinessCheck"
        >
          <div>
            <BussinessClassification
              v-if="bussinessCheck"
              @closeClass="bussinessCheck = false"
              @saveClass="searchBussiness"
              class="businessBox"
              :classification="classification"
              :isSelect="business_classification"
              :number="5"
            ></BussinessClassification>
          </div>
          <el-button class="fl screenBtn" slot="reference" size="small">{{
            $t("adeptBusiness")
          }}</el-button>
        </el-popover>
        <el-popover
          placement="right-start"
          width="600"
          trigger="click"
          v-model="majorShow"
        >
          <div>
            <majorLine
              v-if="majorShow"
              :number="5"
              @cancelLine="majorShow = false"
              @saveLine="saveLine"
              :majorLine="majorLineData"
              :isSelect="havaCheckmajor"
            ></majorLine>
          </div>
          <el-button class="fl screenBtn" slot="reference" size="small">{{
            $t("BussinessMajor")
          }}</el-button>
        </el-popover>
      </div>
      <div
        class="haveSelect clearfix"
        id="cleaHeight"
        v-if="searchResult.length"
      >
        <p class="fl companyScreen">{{ $t("companyhaveSeletc") }} ：</p>
        <div class="fl" style="width: 90%">
          <el-tag
            :class="tag.parentKey"
            v-for="(tag, index) in searchResult"
            style="margin-right: 10px; margin-bottom: 5px"
            :key="index"
            closable
            @close="closetag(tag, index)"
            >{{ tag.label }}</el-tag
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CountrySelect from "~/baseComponents/CountrySelect";
import CitySelect from "~/baseComponents/CitySelect";
import BussinessClassification from "@/baseComponents/BussinessClassification";
import CompanySelect from "~/baseComponents/CompanySelect";
import { getBusinessObject } from "~bac/plugins/filters";
import { shippingLine } from "~bac/utils/enumValue";
import majorLine from "~bac/components/company/business/majorLine";

export default {
  components: {
    CountrySelect,
    CitySelect,
    CompanySelect,
    BussinessClassification,
    majorLine,
  },
  data() {
    return {
      search: {
        // country: null,
        // city: null,
        // company: "",
        // name: "",
        // business_classification: "",
      },
      show_more: false,
      IndustryData: [],
      majorLineData: shippingLine,
      association: [],
      industry: [],
      business_classification: {
        Convertional: [],
        "Dangerous Cargo": [],
        "Other convertional": [],
        "Particular Cargo": [],
        Services: [],
      },
      majorShow: false,
      bussinessCheck: false,
      associationCheck: false,
      industryCheck: false,
      classification: [],
      havaCheckmajor: [],
      havaCheckCertificate: [], //  中转的协会
      searchResult: [],
    };
  },
  watch: {
    show_more() {
      if (this.show_more) {
        if (this.searchResult.length > 0) {
          this.$emit("heightChange", 430);
        } else {
          this.$emit("heightChange", 370);
        }
      } else {
        this.$emit("heightChange", 318);
      }
    },
    searchResult() {
      if (this.searchResult.length > 0) {
        this.$emit("heightChange", 430);
      } else {
        this.$emit("heightChange", 370);
      }
    },
  },
  methods: {
    getChange() {
      var heights = document.getElementById("cleaHeight");
      var computedStyle = window.getComputedStyle(heights, null)["height"];
    },
    resetChange() {
        this.search = {};
        // this.search.company_name = "";
        this.searchResult = [];
        // this.$refs.companySelect.clearInfo();
    },
    searchChange() {
      this.$emit("search", this.search);
    },
    closetag(tag, index) {
      this.searchResult.splice(index, 1);
      if (tag.type === 1) {
        const deleteIndex = this.association.findIndex((res) => {
          // 不写return返回的是-1，谜
          return res.value == tag.value;
        });
        if (deleteIndex !== -1) {
          this.association.splice(deleteIndex, 1);
        }
      }
      if (tag.type === 2) {
        this.industry = [];
      }
      if (tag.type === 3) {
        let classConIndex = this.business_classification.Convertional.findIndex(
          (res) => {
            return res.id == tag.value;
          }
        );
        if (classConIndex !== -1) {
          this.business_classification.Convertional.splice(classConIndex, 1);
        }

        let classDcIndex = this.business_classification[
          "Dangerous Cargo"
        ].findIndex((res) => {
          return res.id == tag.value;
        });
        if (classDcIndex !== -1) {
          this.business_classification["Dangerous Cargo"].splice(
            classDcIndex,
            1
          );
        }

        let classOcoIndex = this.business_classification[
          "Other convertional"
        ].findIndex((res) => {
          return res.id == tag.value;
        });
        if (classOcoIndex !== -1) {
          this.business_classification["Other convertional"].splice(
            classOcoIndex,
            1
          );
        }

        let classPcIndex = this.business_classification[
          "Particular Cargo"
        ].findIndex((res) => {
          return res.id == tag.value;
        });
        if (classPcIndex !== -1) {
          this.business_classification["Particular Cargo"].splice(
            classPcIndex,
            1
          );
        }

        let classCsIndex = this.business_classification.Services.findIndex(
          (res) => {
            return res.id == tag.value;
          }
        );
        if (classCsIndex !== -1) {
          this.business_classification.Services.splice(classCsIndex, 1);
        }
      }
      if (tag.type === 4) {
        const deleteIndex = this.havaCheckmajor.findIndex((res) => {
          // 不写return返回的是-1，谜
          return res.id == tag.value;
        });
        if (deleteIndex !== -1) {
          this.havaCheckmajor.splice(deleteIndex, 1);
        }
      }
      this.disposeReqFn();
    },
    async showBusiness() {
      this.classification = getBusinessObject([], true);
    },
    saveLine(e) {
      if (e.length > 0) {
        this.havaCheckmajor = [];
        this.majorLineData.forEach((item) => {
          e.forEach((id) => {
            if (item.id === id) {
              this.havaCheckmajor.push(item);
            }
          });
        });
      }
      this.concatSearchResult();
      this.majorShow = false;
      this.disposeReqFn();
    },
    searchBussiness(e) {
      if (e.length > 0) {
        this.business_classification = {
          Convertional: [],
          "Dangerous Cargo": [],
          "Other convertional": [],
          "Particular Cargo": [],
          Services: [],
        };
        if (this.classification.Convertional.length > 0) {
          this.classification.Convertional.forEach((item) => {
            e.forEach((id) => {
              if (item.id === id) {
                this.business_classification.Convertional.push(item);
              }
            });
          });
        } else {
          this.business_classification.Convertional = [];
        }

        if (this.classification["Dangerous Cargo"].length > 0) {
          this.classification["Dangerous Cargo"].forEach((item) => {
            e.forEach((id) => {
              if (item.id === id) {
                this.business_classification["Dangerous Cargo"].push(item);
              }
            });
          });
        } else {
          this.business_classification["Dangerous Cargo"] = [];
        }

        if (this.classification["Other convertional"].length > 0) {
          this.classification["Other convertional"].forEach((item) => {
            e.forEach((id) => {
              if (item.id === id) {
                this.business_classification["Other convertional"].push(item);
              }
            });
          });
        } else {
          this.business_classification["Other convertional"] = [];
        }

        if (this.classification["Particular Cargo"].length > 0) {
          this.classification["Particular Cargo"].forEach((item) => {
            e.forEach((id) => {
              if (item.id === id) {
                this.business_classification["Particular Cargo"].push(item);
              }
            });
          });
        } else {
          this.business_classification["Particular Cargo"] = [];
        }

        if (this.classification.Services.length > 0) {
          this.classification.Services.forEach((item) => {
            e.forEach((id) => {
              if (item.id === id) {
                this.business_classification.Services.push(item);
              }
            });
          });
        } else {
          this.business_classification.Services = [];
        }
      }
      this.concatSearchResult();
      this.bussinessCheck = false;
      this.disposeReqFn();
    },

    industryShow() {
      this.$nextTick(async () => {
        this.$refs.industryCasader.$children[0].$refs.input.click();
      });
    },
    // 合并标签
    concatSearchResult() {
      this.searchResult = [];
      let certificateArr = [];
      let industry = [];
      let bussiness = [];
      let havaCheckmajor = [];
      if (this.association.length > 0) {
        this.association.forEach((item) => {
          let obj = {
            value: item.value,
            parentKey: "certificate",
            label: item.name,
            type: 1,
          };
          certificateArr.push(obj);
        });
      }
      if (this.industry.length > 0) {
        let label = industryFormat(this.industry[1], this.LOCALE);
        let arr = label.split("-");
        let obj = {
          value: this.industry[1],
          parentKey: "industry",
          label: this.industry[0] + "/" + arr[1],
          type: 2,
        };
        industry.push(obj);
      }
      let bussinessConcat = this.business_classification.Convertional.concat(
        this.business_classification["Dangerous Cargo"],
        this.business_classification["Other convertional"],
        this.business_classification["Particular Cargo"],
        this.business_classification.Services
      );
      if (bussinessConcat.length > 0) {
        bussinessConcat.forEach((item) => {
          let obj = {
            value: item.id,
            parentKey: "business_classification",
            label: this.LOCALE === "en" ? item.desc_en : item.desc_zh,
            type: 3,
          };
          bussiness.push(obj);
        });
      }
      if (this.havaCheckmajor.length > 0) {
        this.havaCheckmajor.forEach((item) => {
          let obj = {
            value: item.id,
            parentKey: "lanes",
            label: item.name,
            type: 4,
          };
          havaCheckmajor.push(obj);
        });
      }
      this.searchResult = this.searchResult.concat(
        certificateArr,
        industry,
        bussiness,
        havaCheckmajor
      );
    },
    // 处理参数
    disposeReqFn() {
      let bussinessConcat = this.business_classification.Convertional.concat(
        this.business_classification["Dangerous Cargo"],
        this.business_classification["Other convertional"],
        this.business_classification["Particular Cargo"],
        this.business_classification.Services
      );
      if (bussinessConcat.length > 0) {
        let arr = bussinessConcat.map((item) => item.id);
        this.search.business_classification = arr.join();
      } else {
        this.search.business_classification = "";
      }
      if (!this.search.company_name) {
        delete this.search.company_name;
      }
      if (this.havaCheckmajor.length > 0) {
        let arr = this.havaCheckmajor.map((item) => item.id);
        this.search.shipping_line = arr.join();
      } else {
        delete this.search.shipping_line;
      }
      this.$emit("search", this.search);
    },
  },
};
</script>
<style lang="less" scoped>
.filterSearch {
  .i_rotate {
    font-size: 18px;
    color: #DCDFE6;
    cursor: pointer;
    border: 1px solid #DCDFE6;
    padding: 5px;
    border-radius: 3px;
  }
  .inputWidth {
    width: 15%;
    margin: 0 12px;
  }
  .haveSelect {
    margin: 10px 0;
    .companyScreen {
      width: 90px;
      line-height: 32px;
    }
    .screenBtn {
      margin-right: 5px;
    }
  }
  .midSearch {
    padding: 20px 0;
    .screenBtn {
      margin-right: 15px;
    }
    p {
      line-height: 32px;
      font-size: 14px;

      padding-right: 10px;
    }
  }
}
</style>