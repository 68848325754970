<template>
  <div class="caseCard">
    <div class="isSelf clearfix">
      <div class="fl info clearfix">
        <div class="fl">
          <el-image
            :src="USER_INFO.avatar ? USER_INFO.avatar : DEFAULT_AVATAR"
            class="avatar"
          ></el-image>
        </div>
        <div class="fr infoDetail">
          <div class="name">
            {{ USER_INFO.name_en | priorFormat(USER_INFO.name_zh, LOCALE) }}
          </div>
          <div class="clearfix job">
            <div class="fl textOverflow">
              {{
                USER_INFO.job_title_en
                  | priorFormat(USER_INFO.job_title_zh, LOCALE)
              }}
            </div>
            <span class="fl">|</span>
            <div
              class="fl textOverflow"
              v-if="JSON.stringify(foreign_info) != '{}'"
            >
              {{ foreign_info.company_name }}
            </div>
            <div class="fl textOverflow" v-else>
              {{
                USER_INFO.company_name_en
                  | priorFormat(USER_INFO.company_name_zh, LOCALE)
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="fr infoBtn">
        <el-button type="text" @click="openMyDetail(USER_INFO)">{{
          $t("preview")
        }}</el-button>
        <span class="line">|</span>
        <el-button
          type="text"
          @click="$router.push('/console/conference/caseCard/admin')"
          >{{ $t("edit") }}</el-button
        >
      </div>
    </div>
    <div class="line"></div>
    <div class="cardTable">
      <pageTitle :title="$t('cardCase')"></pageTitle>
      <!-- <div class="title">
        <span class="mainColor">{{ $t("cardCase") }}</span>
      </div> -->
      <div class="search">
        <filterSearch
          @search="searchData"
          @heightChange="heightChange"
        ></filterSearch>
      </div>
      <div class="tableBlock">
        <el-table
          :data="tableData"
          style="width: 100%"
          :height="tableHeight"
          v-tableHeight="{ bottomOffset: 74 }"
          :tableLoading="loading"
        >
          <el-table-column prop="date" :label="$t('name')" width="180px">
            <template slot-scope="scope">
              <div class="userInfo" @click="openDetail(scope.row)">
                <el-image
                  class="thumb"
                  style="width: 50px; height: 50px; border-radius: 50%"
                  :src="scope.row.avatar || DEFAULT_AVATAR"
                  :fit="'cover'"
                ></el-image>
                <div>
                  <p
                    :title="
                      scope.row.user_name_en
                        | priorFormat(scope.row.user_name_zh, LOCALE)
                    "
                  >
                    {{
                      scope.row.user_name_en
                        | priorFormat(scope.row.user_name_zh, LOCALE)
                    }}
                  </p>
                  <p
                    :title="
                      scope.row.job_title_en
                        | priorFormat(scope.row.job_title_zh, LOCALE)
                    "
                  >
                    {{
                      scope.row.job_title_en
                        | priorFormat(scope.row.job_title_zh, LOCALE)
                    }}
                  </p>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            :label="$t('companyName')"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
                {{
                  scope.row.company_name_en
                    | priorFormat(scope.row.company_name_zh, LOCALE)
                }}
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            :label="$t('email')"
            min-width="80px"
          >
            <template slot-scope="scope">
              {{ scope.row.email | textFormat }}
            </template>
          </el-table-column>
          <el-table-column
            prop="date"
            :label="$t('phone')"
            show-overflow-tooltip
            min-width="80px"
          >
            <template slot-scope="scope">
              <span>
                {{ scope.row.mobile | phoneFormat(scope.row.area_code) }}</span
              >
            </template>
          </el-table-column>
          <template v-if="BACCONFIG.sendCard">
            <el-table-column prop="date" :label="$t('status')" min-width="80px">
              <template slot-scope="scope">
                <div>
                  <span v-if="scope.row.status == 0" style="color: #909399">{{
                    $t("waitHandle")
                  }}</span>
                  <span v-if="scope.row.status == 1" style="color: #67c23a">{{
                    $t("hasChanged")
                  }}</span>
                </div>
              </template>
            </el-table-column>
          </template>
          <el-table-column
            show-overflow-tooltip
            prop="date"
            :label="$t('createTime')"
            min-width="80px"
          >
            <template slot-scope="scope">
              {{ scope.row.created_time | secondFormat }}
            </template>
          </el-table-column>
          <el-table-column prop="date" :label="$t('handle')" width="200px">
            <template slot-scope="scope">
              <el-button type="text" @click="openDetail(scope.row)">{{
                $t("Detail")
              }}</el-button>
              <span class="line1">|</span>
              <template v-if="scope.row.status == 0">
                <el-button
                  type="text"
                  @click="handleChangeStatus(scope.row, 1)"
                  >{{ $t("change") }}</el-button
                >
                <span class="line1">|</span>
                <el-button
                  type="text"
                  @click="handleChangeStatus(scope.row, 2)"
                  >{{ $t("noChange") }}</el-button
                >
              </template>

              <el-button
                v-if="
                  !BACCONFIG.sendCard ||
                  (BACCONFIG.sendCard && scope.row.status == 1)
                "
                @click="deleteData(scope.row)"
                type="text"
                :disabled="scope.row.user_id == USER_INFO.id"
                >{{ $t("delete") }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="paginationBlock">
        <el-pagination
          @current-change="handleChange"
          :page-size="10"
          layout="total, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <cardIsDialog
      :isDetail="true"
      @successChange="successChange"
    ></cardIsDialog>
    <cardDialog :followedId="currentId"></cardDialog>
  </div>
</template>
<script>
import filterSearch from "~bac/components/conference/caseCard/filterSearch";
import pageTitle from "~bac/components/common/pageTitle";
import cardIsDialog from "@/baseComponents/card/cardIsDialog";
import cardDialog from "@/baseComponents/card/cardDialog";
export default {
  components: {
    filterSearch,
    pageTitle,
    cardDialog,
    cardIsDialog,
  },
  data() {
    return {
      activeName: "first",
      loading: false,
      currentPage: 1,
      total: 0,
      tableHeight: 100,
      tableData: [],
      foreign_info: {},
      currentId: null,
      searchDatas: {},
    };
  },
  methods: {
    successChange() {
      this.getList(this.searchDatas);
    },
    searchData(val) {
      if (val.business_classification || val.shipping_line) {
        this.tableHeight = 500;
      }
      this.searchDatas = val;
      this.getList(val);
    },
    async getList(val) {
      this.loading = true;
      let value = Object.assign(this.searchDatas, val);
      let _params = {
        limit: 10,
        start: this.currentPage - 1,
        user_id: this.USER_INFO.id,
      };
      _params.source = this.PJSource;
      let params = Object.assign(_params, value);
      try {
        let result = await this.$store.dispatch(
          "baseConsole/getCollectionUseList",
          params
        );
        if (result.success) {
          this.tableData = result.data;
          this.total = result.total;
        }
        if (this.tableData == 0 && this.currentPage!=1 ){
          //如果当前页数据为0，且分页不为第一页，页数减一（用于处理删除时的情况）
          this.currentPage = this.currentPage - 1;
          this.getList();
          return;
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
      }
    },
    handleChange(e) {
      this.currentPage = e;
      this.getList();
    },
    async handleChangeStatus(info, status) {
      let params = {
        user_id: this.USER_ID,
        followed_user_id: info.user_id,
        source: this.PJSource,
        status: status,
      };
      let result = await this.$store.dispatch(
        "baseConsole/handleChangeCard",
        params
      );
      if (result.success) {
        this.$message({
          message: this.$t("Successful_operation"),
          type: "success",
        });
        this.getList();
      }
    },
    openDetail(row) {
      if (this.BACCONFIG.sendCard) {
        this.$GLOBALEVENT.$emit("OPENISUSERCARD", { show: true, row });
      } else {
        if (row) {
          this.currentId = row.user_id;
        } else {
          this.currentId = this.USER_INFO.id;
        }
        this.$nextTick(() => {
          this.$GLOBALEVENT.$emit("OPENUSERCard", {
            followed_user_id: row.send_user_id,
            emit_company_id: row.send_user_company_id,
            show: true,
          });
        });
      }
    },
    openMyDetail(info) {
      let row = info;
      row.user_id = info.id;
      if (this.BACCONFIG.sendCard) {
        this.$GLOBALEVENT.$emit("OPENISUSERCARD", { show: true, row });
      } else {
        if (row) {
          this.currentId = row.user_id;
        } else {
          this.currentId = this.USER_INFO.id;
        }
        this.$nextTick(() => {
          this.$GLOBALEVENT.$emit("OPENUSERCard", {
            followed_user_id: row.id,
            emit_company_id: row.company_id,
            show: true,
          });
        });
      }
    },
    deleteData(row) {
      let params = {
        company_id: row.company_id,
        visit_company_id: this.USER_INFO.company_id,
        visit_user_id: this.USER_INFO.id,
        operation_type: 1,
        follow_type: 2,
        followed_user_id: row.user_id,
      };
      this.$confirm(`${this.$t("confirmDelete")}？`, this.$t("delete"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(async () => {
          let result = await this.$store.dispatch(
            "baseConsole/cancelFollowUser",
            params
          );
          if (result.success) {
            this.$delTip();
            this.getList();
          }
        })
        .catch(() => {
          // this.$message.error({
          //   showClose: true,
          //   message: "删除失败",
          // });
        });
    },
    heightChange(height) {
      let hei = document.body.clientHeight - height;
      this.tableHeight = hei;
    },
  },
  mounted() {
    this.getList();
    if (this.USER_INFO.foreign_info)
      this.foreign_info = JSON.parse(this.USER_INFO.foreign_info);
  },
};
</script>
<style lang="less" scoped>
.userInfo {
  display: flex;
  align-items: center;
  cursor: pointer;
  .thumb {
    margin-right: 10px;
  }
  div {
    p {
      width: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.caseCard {
  .isSelf {
    height: 87px;
    background: #ffffff;
    border-bottom: 10px solid #f2f2f2;
    padding: 0 30px;
    .info {
      .avatar {
        width: 57px;
        height: 57px;
        border-radius: 50%;
        margin: 12px 12px 12px 0;
      }
      .infoDetail {
        margin: 18px 0;
      }
      .name {
        font-weight: 800;
        font-size: 16px;
      }
      .job {
        width: 800px;
        span {
          color: #909399;
          margin: 5px 5px 5px 0;
          font-size: 12px;
        }
        div {
          color: #909399;
          margin: 5px 5px 5px 0;
          font-size: 12px;
          max-width: 48%;
        }
      }
    }
    .infoBtn {
      margin: 20px 0;
      .line {
        color: #e9e9e9;
        margin: 0 5px;
      }
    }
  }
  .cardTable {
    .title {
      border-bottom: 1px solid #c0c4cc;
      padding: 15px 12px;
      span {
        border-bottom: 1px solid #027fff;
        padding: 15px 0;
      }
    }
    .search {
      padding: 0 12px 12px;
    }
  }
  .tableData {
    padding: 0 12px;
  }
}
.textOverflow2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.line1 {
  color: #e9e9e9;
  margin: 0 5px;
}
</style>